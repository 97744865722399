import { ReferenceMapping } from '@/services/http/wap/types'

export const LOGOS_MAPPING: ReferenceMapping[] = [
  {
    name: 'title',
    path: 'baslik',
    type: 'query',
  },
  {
    name: 'image',
    path: 'logo',
    type: 'query',
  },
  {
    name: 'imageWhite',
    path: 'beyazlogo',
    type: 'query',
  },
  {
    name: 'show',
    path: 'goster',
    type: 'query',
  },
  {
    name: 'anchor',
    path: 'webSiteLinki',
    type: 'query',
  },
]
